<template>
  <v-app>
    <BandejaEntrada
      v-on:pagina_anterior="page--"
      v-on:pagina_siguiente="page++"
      v-on:ver_documento="verDocumento"
      :page="page"
      :tokens="tokens"
      :folders="folders"
      :folderPorPagina="folderPorPagina"
      :todosLosFolder="todosLosFolder"
    />
    <v-dialog v-model="dialogo_respuesta_delegado" max-width="700">
      <v-sheet>
        <v-row>
          <v-col>
            <v-container>
              <v-sheet
                color="indigo darken-4"
                dark
                rounded=""
                elevation="5"
                class="headline text-center font-weight-light mt-2"
              >
                <v-row>
                  <v-col>
                    <div v-text="documento.nombre_documento"></div>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>
        <v-container class="mt-2 mx-5" fluid>
          <v-row>
            <div v-text="'Detalles'" class="header font-weight-light"></div>
          </v-row>
          <v-row>
            <v-col>
              <div v-text="'Autor'" class="title font-weight-medium"></div>
              <div
                v-text="documento.remitente"
                class="body font-weight-light ml-3"
              ></div>
            </v-col>
            <v-col>
              <div v-text="'Correo'" class="title font-weight-medium"></div>
              <div
                v-text="documento.data.correo_empleado"
                class="body font-weight-light ml-3"
              ></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div v-text="'Comentario'" class="title font-weight-medium"></div>
              <div
                v-text="documento.comentario"
                class="body font-weight-light ml-3"
              ></div>
            </v-col>
            <v-col> </v-col>
          </v-row>
          <v-row v-if="ruta == 'Respondido'">
            <v-col>
              <v-window class="elevation-1" v-model="window">
                <v-window-item
                  v-for="(archivo, index) in documento.data.datos_adjuntos"
                  :key="index"
                >
                  <v-card class="mx-auto" max-width="75%" flat>
                    <v-row>
                      <v-col>
                        <div
                          v-text="'Archivos del caso'"
                          class="headline font-weight-light ml-3"
                        ></div>
                      </v-col>
                      <v-col cols="3">
                        <v-btn @click="window++" text color="indigo darken-4"
                          >Siguiente</v-btn
                        >
                      </v-col>
                    </v-row>

                    <v-card-text>
                      <v-row class="mb-4" align="center">
                        <v-avatar
                          :src="require('../../assets/img.png')"
                          class="mr-4"
                        ></v-avatar>
                      </v-row>

                      <br />
                      <Archivos :archivo="archivo" :width="800" :height="500" />
                    </v-card-text>
                  </v-card>
                </v-window-item>
              </v-window>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-dialog>
  </v-app>
</template>
<script>
import BandejaEntrada from "../../components/BandejaEntrada";
import bandeja_entrada from "../../mixins/bandeja_entrada";
import Archivos from "../../components/Archivos";
const axios = require("axios").default;
export default {
  mixins: [bandeja_entrada],
  components: {
    BandejaEntrada,
    Archivos,
  },
  data() {
    return {
      dialogo_respuesta_delegado: false,
      documento: {
        remitente: "",
        comentario: "",
        nombre_documento: "",
        data: {
          correo_empleado: "",
          datos_adjuntos: "",
        },
      },
      window: 0,
    };
  },
  watch: {
    ruta() {
      this.iniciar();
    },
  },
  created() {
    this.iniciar();
  },
  computed: {
    ruta() {
      //Se creo esta funcion computada puesto que no estaba detectando la nueva ruta cada vez que se creaba

      console.log(this.$route.params.id);
      return this.$route.params.id;
    },
  },
  methods: {
    verDocumento(documento) {
      console.log(documento);
      this.documento = documento;
      this.dialogo_respuesta_delegado = true;
    },
    async iniciar() {
      this.$store.state.load_linear = true;
      this.tokens = [];
      this.folders = [];
      this.folderPorPagina = [];
      //Aqui ahora debo pasar un padre que indique que tokens voy a buscar


      this.$store.commit("auth", "parse");
      console.log(this.$store.state.header);
      console.log(this.$route.params);

      console.log(this.$store.state.dataUser.dataUser.sector);
      let buscar = JSON.stringify({
        sector: this.$store.state.dataUser.dataUser.sector,
        estado: atob(this.ruta),
      });
      buscar = encodeURIComponent(buscar);
      console.log(buscar);

      let response = await axios.get(
        "https://siett.educandote.co/parse/classes/prueba01RespuestasEmpleados?where=" +
          buscar,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
            "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
            Authorization: this.$store.state.header,
          },
        }
      );
      console.log(response.data);
      let i = -1;
      let obj;
      for (let index = 0; index < response.data.results.length; index++) {
        obj = {
          data: response.data.results[index],
          title: response.data.results[index].nombre_documento,
          subtitle:
            "Nombre empleado" + response.data.results[index].nombre_empleado,
          remitente: response.data.results[index].nombre_empleado,
          comentario: response.data.results[index].comentario,
          nombre_documento: response.data.results[index].nombre_documento,
          tipoDocumental: response.data.results[index].tipo_documental,
        };
        if (index < 5) {
          this.folders.push(obj);
        }
        if (index % 5 == 0) {
          i++;
          this.folderPorPagina.push([]);
        }
        this.folderPorPagina[i].push(obj);
        this.todosLosFolder.push(obj);
        this.$store.commit("dataUser/SET_FOLDERS_USER", obj);
      }
      this.$store.state.load_linear = false;
    },
  },
};
</script>